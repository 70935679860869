import React from 'react'
import './Footer.css'

function Footer() {
  return (
    <div className='footer'>
        <p>© Shelley Cotterell</p>
        <p>shelleycotterellsc@gmail.com</p>
    </div>
  )
}

export default Footer