
import 'firebase/compat/firestore'
import firebase from 'firebase/compat/app'


const firebaseConfig = {
  apiKey: "AIzaSyAdFlJvJbquwNdOev8K_S-h-pAebD_w_Oo",
  authDomain: "shells-stories.firebaseapp.com",
  projectId: "shells-stories",
  storageBucket: "shells-stories.appspot.com",
  messagingSenderId: "845478016161",
  appId: "1:845478016161:web:e25b2d4b0d02e083956ce0",
  measurementId: "G-FV65W2BSX9"
  };

//   const app = initializeApp(firebaseConfig);
const app = firebase.initializeApp(firebaseConfig)

  export const admin = firebase;
  export const db = app.firestore();
//   export const auth = app.auth();
//   export const storage = app.storage();
//   export const database = app.database()
  export default app  