import React, { useState, useEffect } from 'react';
import './App.css';
import './animations.css'

import AudioBox from './components/AudioBox/AudioBox';
import Footer from './components/Footer/Footer';

import manWar from './assets/audio/man-of-war.m4a'
import xmas from './assets/audio/bext-xmas.m4a'
import hero from './assets/audio/hero.m4a'
import fair from './assets/audio/not-fair.m4a'
import safari from './assets/audio/on-safari.m4a'
import bird from './assets/audio/bird.m4a'
import dassie from './assets/audio/dassie.m4a'
import denver from './assets/audio/denver.m4a'
import hyene from './assets/audio/hyene.m4a'
import pinkie from './assets/audio/pinkie.m4a'
import rabbit from './assets/audio/rabbit.m4a'


import bear from './assets/images/bear.png'
import birdie from './assets/images/birdie.png'
import bunneh from './assets/images/bunneh.png'
import dolph from './assets/images/dolph.png'
import lyrax from './assets/images/lyrax.png'
import hyena from './assets/images/hyena.png'

import gir from './assets/images/gir.png'
import dress from './assets/images/dress.png'
import hat from './assets/images/hat.png'
import jelly from './assets/images/jelly.png'
import squir from './assets/images/squir.png'

import bg from './assets/images/bg-logo.jpg'
import bg1 from './assets/images/1.png'
import { db, admin } from './firebase';
function App() {

  document.addEventListener('play', function(e){
    var audios = document.getElementsByTagName('audio');
    for(var i = 0, len = audios.length; i < len;i++){
        if(audios[i] != e.target){
            audios[i].pause();
        }
    }
}, true);

  const [playing, setPlaying] = useState('')

  useEffect(() => {
    if (playing !== "") {
    addListen({title: playing});
    }
  }, [playing])


  function addListen({title}) {
    db.collection("plays").doc("ct").update({
      [`${title}`]: admin.firestore.FieldValue.increment(1),
    })
  }

  return (
    <div className="App bg-pan-tl">

      <div className="app-image">
        <img src={bg} alt={'main'} />
      </div>

      <div className="story-group">
        {/* <div className="sg-title">
          <h2>Animal Stories</h2>
          <p>Ages 3-5</p>
        </div> */}
        <div className="sg-box">
        <AudioBox playing={playing} setPlaying={setPlaying} title={'The Boy and the Bird'} imageLink={birdie} filePath={bird} />
        <AudioBox playing={playing} setPlaying={setPlaying} title={'Dassie and the Fire'} imageLink={lyrax} filePath={dassie} />
        <AudioBox playing={playing} setPlaying={setPlaying} title={'Denver the Dolphin'} imageLink={dolph} filePath={denver} />
        <AudioBox playing={playing} setPlaying={setPlaying} title={'Pinkie and Paulie Learn a Lesson'} imageLink={bear} filePath={pinkie} />
        <AudioBox playing={playing} setPlaying={setPlaying} title={'The Day the Rabbit Came to Stay'} imageLink={bunneh} filePath={rabbit} />
        <AudioBox playing={playing} setPlaying={setPlaying} title={'The Hyena and the King'} imageLink={hyena} filePath={hyene} />

        </div>
      </div>

      <div className="story-group">
        {/* <div className="sg-title">
          <h2>Family Stories</h2>
          <p>Ages 5-7</p>
        </div> */}
        <div className="sg-box">
        <AudioBox playing={playing} setPlaying={setPlaying} title={'The Best Christmas Ever'} imageLink={hat} filePath={xmas} />
        <AudioBox playing={playing} setPlaying={setPlaying} title={'On Safari'} imageLink={gir} filePath={safari} />
        <AudioBox playing={playing} setPlaying={setPlaying} title={'Hero In The Road'} imageLink={squir} filePath={hero} />
        <AudioBox playing={playing} setPlaying={setPlaying} title={'It\'s Not Fair'} imageLink={dress} filePath={fair} />
        <AudioBox playing={playing} setPlaying={setPlaying} title={'Portuguese Man of War'} imageLink={jelly} filePath={manWar} />
        </div>
      </div>

      <Footer />

    </div>
  );
}

export default App;
