import React from 'react'
import './AudioBox.css'




function AudioBox({ filePath, title, imageLink, playing, setPlaying }) {
  return (
    <div className={playing === title ? 'audio-box ab-active' : 'audio-box slide-in-fwd-center'}>
        <div className="ab-left">
            <img 
            alt='display'
            src={imageLink}
            />
        </div>
        <div className="ab-right">
        <h1>{title}</h1>  
            <audio onPlay={() => { 
              setPlaying(title)}} controls controlsList='nodownload noplaybackrate' src={filePath} />
        </div>
            
    </div>
  )
}

export default AudioBox